import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';
import { linkResolver } from '../utils/linkResolver';

const SectionTextContent = ({ text, useWhiteTextColor }) => {
  let textColor = 'text-gray-700';

  if (useWhiteTextColor) {
    textColor = 'text-gray-100';
  }

  return (
    <div className={`${textColor} my-6 leading-relaxed lg:mb-32`}>
      <PrismicRichText field={text} components={htmlSerializer} />
    </div>
  );
};

export default SectionTextContent;
