import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';
import Layout from '../components/layout';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import SectionTitle from '../components/sectionTitle';
import SectionTextContent from '../components/sectionTextContent';
import HeroContent from '../components/heroContent';
import { convertToBgImage } from 'gbimage-bridge';
import { RichText } from 'prismic-reactjs';
import { phoneNumberHtmlSerializer } from '../utils/phoneNumberHtmlSerializer';

const ScheduleYourVisitPage = ({ data }) => {
  const {
    title,
    banner_image,
    banner_graphic,
    banner_description_content,
    top_section_title,
    top_section_text,
    bottom_section_title,
    bottom_section_text,
    bottom_section_call_to_action,
  } = data.prismicContactUs.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  const topTitle = RichText.asText(top_section_title.richText);

  return (
    <Layout
      pageTitle='Schedule Your Visit'
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      useTallBannerBackground
      contentInsideBackground={() => (
        <FullWidthContainer className='xl:mt-6 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(title.richText)}
            heroDescription={banner_description_content.richText}
            rightAlign
            isTitleOnBottom
          />
        </FullWidthContainer>
      )}
    >
      <div className='mt-20 px-8 lg:px-32 mega:px-48'>
        <SectionTitle text={topTitle} usePrimaryTextColor />
        <SectionTextContent text={top_section_text.richText} />
      </div>
      <div className='top-slant mg:-mt-10 -mt-4 mb-8 flex flex-col items-center px-8 pt-12 pb-8 text-center text-denimblue md:pt-24 lg:-mt-16 lg:mb-12 lg:pb-16 lg:pt-32'>
        <div className='text-lg font-semibold lg:mb-4 lg:text-2xl'>
          <PrismicRichText
            field={bottom_section_call_to_action.richText}
            components={{
              hyperlink: phoneNumberHtmlSerializer,
            }}
          />
        </div>
        <div className='text-left text-gray-800 lg:text-justify'>
          <PrismicRichText
            field={bottom_section_text.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ScheduleYourVisitPageQuery {
    prismicContactUs {
      data {
        banner_description_content {
          richText
        }
        banner_graphic {
          alt
          url
          gatsbyImageData
        }
        banner_image {
          alt
          url
          gatsbyImageData
        }
        bottom_images {
          image {
            alt
            url
            gatsbyImageData
          }
        }
        bottom_section_text {
          richText
        }
        bottom_section_call_to_action {
          richText
        }
        bottom_section_title {
          richText
        }
        title {
          richText
        }
        top_section_text {
          richText
        }
        top_section_title {
          richText
        }
      }
    }
  }
`;

export default ScheduleYourVisitPage;
